<template>
  <div>
    <div class="d-flex align-items-center mb-4">
      <h3>{{ $t('terms.supplierTerms.purchaseHistoryTop20.header') }}</h3>
    </div>
    <Table
      v-loading="loading"
      :data="tableDataSortedByEstimatedAnnualPurchaseAmount"
      :columns="columns"
      show-index
      border
      rounded
      @row-click="openSelectedProductModal($event)"
    >
      <template #header-averagePrice="{ column: { header } }">
        <div>
          {{ header }}
          <small class="d-block text-muted text-nowrap">
            {{ $t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.last6Months') }}
          </small>
        </div>
      </template>
      <template #header-annualImpact="{ column: { header } }">
        <div>
          {{ header }}
          <small class="d-block text-muted">{{ priceReductionText }}</small>
        </div>
      </template>
      <template #header-targetPrice="{ column: { header } }">
        <div>
          <div class="d-flex align-items-center">
            <span :class="$direction === 'ltr' ? 'me-1' : 'ms-1'">{{ header }}</span>
          </div>
          <small class="d-block text-muted">{{ priceReductionText }}</small>
        </div>
      </template>
      <template
        #cell-sku="{
          rowData: {
            product: { sku },
          },
        }"
        ><p :style="{ direction: 'ltr' }">{{ sku }}</p></template
      >
      <template
        #cell-name="{
          rowData: {
            product: { name },
          },
        }"
      >
        <TruncatedText>{{ name }}</TruncatedText>
      </template>
      <template
        #cell-supplier="{
          rowData: {
            supplier: { name },
          },
        }"
      >
        <TruncatedText>{{ name }}</TruncatedText>
      </template>
      <template #cell-averagePrice="{ rowData: { averagePrice } }">
        {{ formatCurrency(averagePrice) }}
      </template>
      <template #cell-estimatedAnnualQuantity="{ rowData: { estimatedAnnualQuantity } }">
        {{ formatFloat2Digits(estimatedAnnualQuantity) }}</template
      >
      <template #cell-estimatedAnnualPurchaseAmount="{ rowData: { estimatedAnnualPurchaseAmount } }">
        {{ formatCurrency(estimatedAnnualPurchaseAmount) }}
      </template>
      <template #cell-annualImpact="{ rowData: { annualImpact } }">
        <p :class="annualImpact > 0 ? 'text-success' : annualImpact < 0 ? 'text-danger' : ''">
          {{ formatCurrency(annualImpact !== 0 ? annualImpact * -1 : annualImpact) }}
        </p>
      </template>
      <template #cell-targetPrice="{ rowData: { targetPrice } }">
        {{ formatCurrency(targetPrice) }}
      </template>
    </Table>
  </div>
</template>

<script>
import { useCurrency } from '@/locale/useCurrency';

import { TruncatedText } from '@/modules/core';
import { Table } from '@/modules/core/components';
import { formatCurrency, formatFloat2Digits } from '@/modules/purchase-management/purchaseManagementFormatters';
import { openProductModal } from '@/modules/products/store/product-modal';
import { computed } from 'vue';

const TABLE_HEADERS = {
  SKU: 'sku',
  NAME: 'name',
  SUPPLIER: 'supplier',
  AVERAGE_PRICE: 'averagePrice',
  ESTIMATED_ANNUAL_QUANTITY: 'estimatedAnnualQuantity',
  ESTIMATED_ANNUAL_PURCHASE_AMOUNT: 'estimatedAnnualPurchaseAmount',
  ANNUAL_IMPACT: 'annualImpact',
  TARGET_PRICE: 'targetPrice',
};

export default {
  components: { Table, TruncatedText },
  props: {
    priceReduction: { type: Number, default: () => 0 },
    topPurchasedProducts: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  setup(props) {
    const { currencyFormat } = useCurrency();

    const tableDataSortedByEstimatedAnnualPurchaseAmount = computed(() =>
      [...props.topPurchasedProducts].sort(
        (product1, product2) => product2.estimatedAnnualPurchaseAmount - product1.estimatedAnnualPurchaseAmount
      )
    );
    const openSelectedProductModal = (indexOfSelectedProduct) => {
      const selectedProductId = tableDataSortedByEstimatedAnnualPurchaseAmount.value[indexOfSelectedProduct].product.id;
      openProductModal(selectedProductId);
    };
    return {
      openSelectedProductModal,
      tableDataSortedByEstimatedAnnualPurchaseAmount,
      formatCurrency: (number) => formatCurrency(number, currencyFormat.value),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.sku'),
          key: TABLE_HEADERS.SKU,
          width: '135px',
        },
        {
          header: this.$t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.name'),
          key: TABLE_HEADERS.NAME,
          width: '182px',
        },
        {
          header: this.$t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.supplier'),
          key: TABLE_HEADERS.SUPPLIER,
          width: '173px',
        },
        {
          header: this.$t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.averagePrice'),
          key: TABLE_HEADERS.AVERAGE_PRICE,
          width: '168px',
        },
        {
          header: this.$t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.estimatedAnnualQuantity'),
          key: TABLE_HEADERS.ESTIMATED_ANNUAL_QUANTITY,
          width: '154px',
        },
        {
          header: this.$t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.estimatedAnnualPurchaseAmount'),
          key: TABLE_HEADERS.ESTIMATED_ANNUAL_PURCHASE_AMOUNT,
          width: '175px',
        },
        {
          header: this.$t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.annualImpact'),
          key: TABLE_HEADERS.ANNUAL_IMPACT,
          width: '165px',
        },
        {
          header: this.$t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.targetPrice'),
          key: TABLE_HEADERS.TARGET_PRICE,
          width: '165px',
        },
      ];
    },
    priceReductionText() {
      return `${
        this.priceReduction < 0
          ? this.$t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.priceReduction')
          : this.$t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.priceCreep')
      } ${Math.abs(this.priceReduction)}%`;
    },
  },
  methods: {
    formatFloat2Digits(number) {
      return formatFloat2Digits(number) ?? '-';
    },
  },
};
</script>
