import { SUPPLIERS_TABLE_HEADERS } from './suppliersTableConstants';
import { clone } from 'ramda';
import { mutateComparisonItems } from '../../purchaseManagement';

export const getSuppliersCompare = (direction, columnKey) => {
  switch (columnKey) {
    case SUPPLIERS_TABLE_HEADERS.SUPPLIER:
      return (a, b) =>
        a.supplier.name.localeCompare(b.supplier.name) * direction ||
        a.supplier.id.localeCompare(b.supplier.id) * direction;

    case SUPPLIERS_TABLE_HEADERS.PRODUCT_COUNT:
      return (a, b) =>
        (a.productCount - b.productCount) * direction || a.supplier.id.localeCompare(b.supplier.id) * direction;

    case SUPPLIERS_TABLE_HEADERS.TOTAL_PURCHASES:
      return (a, b) => (a.price - b.price) * direction || a.supplier.id.localeCompare(b.supplier.id) * direction;

    case SUPPLIERS_TABLE_HEADERS.ANNUAL_IMPACT:
      return (a, b) =>
        (a.annualImpact - b.annualImpact) * direction || a.supplier.id.localeCompare(b.supplier.id) * direction;

    default:
      return () => 0;
  }
};

const mutateSupplierItems = (suppliers, activeSort, supplierSearchFilter) => {
  return suppliers
    .filter(({ supplier }) => (supplierSearchFilter ? supplier.name.includes(supplierSearchFilter) : true))
    .map(({ supplier, amount, productCount, ...rest }) => {
      return {
        supplier,
        productCount,
        price: amount,
        ...rest,
      };
    })
    .sort(getSuppliersCompare(activeSort.direction, activeSort.columnKey));
};

export const mutateSupplierSummary = ({ aggregatedData, activeSort, supplierSearchFilter }) => {
  const updatedSupplierData = aggregatedData.supplierPurchaseSummary.map((supplierData) => {
    const productSummary = aggregatedData.productPurchaseSummary.filter(
      ({ supplierId }) => supplierId === supplierData.supplier.id
    );
    if (productSummary.length === 0) {
      return {
        ...supplierData,
        productCountWithDifferentPrices: null,
        difference: null,
        annualImpact: null,
      };
    }
    let productCountWithDifferentPrices = 0;
    let difference = 0;
    let annualImpact = 0;
    productSummary.forEach((product) => {
      if (product.hasDifference === true) {
        productCountWithDifferentPrices += 1;
        annualImpact += product.annualImpactOfDifference;
        difference += product.amountOfDifference;
      }
    });
    return {
      ...supplierData,
      productCountWithDifferentPrices,
      difference,
      annualImpact,
    };
  });

  return mutateSupplierItems(
    updatedSupplierData.map((supplierRow) => {
      const percentageChange =
        Math.round((supplierRow.difference / (supplierRow.amount - supplierRow.difference)) * 10000) / 100;

      return {
        ...supplierRow,
        percentageChange,
      };
    }),
    activeSort,
    supplierSearchFilter
  );
};

export const mutateSuppliers = ({ objectifiedData, activeSort, supplierSearchFilter, isComparison }) => {
  if (!objectifiedData.productPurchaseSummary || !objectifiedData.supplierPurchaseSummary) {
    return [];
  }
  let hasComparisonData = false;
  const clonedObjectifiedData = clone(objectifiedData);
  clonedObjectifiedData.supplierPurchaseSummary = mutateSupplierSummary({
    aggregatedData: clonedObjectifiedData,
    activeSort,
    supplierSearchFilter,
  });
  if (clonedObjectifiedData.secondSummaryQuery) {
    hasComparisonData = true;
  }

  if (isComparison && hasComparisonData)
    return mutateComparisonItems({
      objectifiedData: clonedObjectifiedData,
      activeSort,
      searchFilter: supplierSearchFilter,
      key: 'supplier',
      mutationCallback: mutateSupplierItems,
    });

  return clonedObjectifiedData.supplierPurchaseSummary.map((data) => {
    return {
      'comparison-0': data,
    };
  });
};
