var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-items-center mb-4"},[_c('h3',[_vm._v(_vm._s(_vm.$t('terms.supplierTerms.purchaseHistoryTop20.header')))])]),_c('Table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.tableDataSortedByEstimatedAnnualPurchaseAmount,"columns":_vm.columns,"show-index":"","border":"","rounded":""},on:{"row-click":function($event){return _vm.openSelectedProductModal($event)}},scopedSlots:_vm._u([{key:"header-averagePrice",fn:function({ column: { header } }){return [_c('div',[_vm._v(" "+_vm._s(header)+" "),_c('small',{staticClass:"d-block text-muted text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.last6Months'))+" ")])])]}},{key:"header-annualImpact",fn:function({ column: { header } }){return [_c('div',[_vm._v(" "+_vm._s(header)+" "),_c('small',{staticClass:"d-block text-muted"},[_vm._v(_vm._s(_vm.priceReductionText))])])]}},{key:"header-targetPrice",fn:function({ column: { header } }){return [_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{class:_vm.$direction === 'ltr' ? 'me-1' : 'ms-1'},[_vm._v(_vm._s(header))])]),_c('small',{staticClass:"d-block text-muted"},[_vm._v(_vm._s(_vm.priceReductionText))])])]}},{key:"cell-sku",fn:function({
        rowData: {
          product: { sku },
        },
      }){return [_c('p',{style:({ direction: 'ltr' })},[_vm._v(_vm._s(sku))])]}},{key:"cell-name",fn:function({
        rowData: {
          product: { name },
        },
      }){return [_c('TruncatedText',[_vm._v(_vm._s(name))])]}},{key:"cell-supplier",fn:function({
        rowData: {
          supplier: { name },
        },
      }){return [_c('TruncatedText',[_vm._v(_vm._s(name))])]}},{key:"cell-averagePrice",fn:function({ rowData: { averagePrice } }){return [_vm._v(" "+_vm._s(_vm.formatCurrency(averagePrice))+" ")]}},{key:"cell-estimatedAnnualQuantity",fn:function({ rowData: { estimatedAnnualQuantity } }){return [_vm._v(" "+_vm._s(_vm.formatFloat2Digits(estimatedAnnualQuantity)))]}},{key:"cell-estimatedAnnualPurchaseAmount",fn:function({ rowData: { estimatedAnnualPurchaseAmount } }){return [_vm._v(" "+_vm._s(_vm.formatCurrency(estimatedAnnualPurchaseAmount))+" ")]}},{key:"cell-annualImpact",fn:function({ rowData: { annualImpact } }){return [_c('p',{class:annualImpact > 0 ? 'text-success' : annualImpact < 0 ? 'text-danger' : ''},[_vm._v(" "+_vm._s(_vm.formatCurrency(annualImpact !== 0 ? annualImpact * -1 : annualImpact))+" ")])]}},{key:"cell-targetPrice",fn:function({ rowData: { targetPrice } }){return [_vm._v(" "+_vm._s(_vm.formatCurrency(targetPrice))+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }