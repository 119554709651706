import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

import { TOP_PURCHASED_PRODUCTS_QUERY } from './graphql';
import { computed } from 'vue';

export function useTopPurchasedProducts(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(TOP_PURCHASED_PRODUCTS_QUERY, variables, () => ({
    enabled: !!variables.value.limit && !!variables.value.tenantId,
  }));

  const topPurchasedProducts = computed(() => (result.value ? result.value.orderItemAggregationNew.nodes ?? [] : []));

  onError((err) => {
    console.error('useTopPurchasedProducts', err);
    error();
  });

  return {
    topPurchasedProducts,
    loading,
    refetch,
  };
}
