<template>
  <DashboardCard :title="$t('terms.supplierTerms.purchaseHistoryTop20.annualImpactSlider.header')">
    <div class="cards-body">
      <el-slider
        v-model="sliderValue"
        :min="minimum"
        :max="maximum"
        :marks="marks"
        class="mx-5"
        :format-tooltip="formatPercent"
        @input="$emit('change', sliderValue)"
      />
      <div class="calcuated-price-summary d-flex justify-content-center">
        <div :class="$direction === 'rtl' ? 'ms-7' : 'me-7'" class="d-flex flex-column align-items-center">
          <h3>{{ formatPercent(sliderValue) }}</h3>
          <small class="text-muted">{{
            sliderValue > 0
              ? $t('terms.supplierTerms.purchaseHistoryTop20.annualImpactSlider.priceCreep')
              : $t('terms.supplierTerms.purchaseHistoryTop20.annualImpactSlider.priceReduction')
          }}</small>
        </div>
        <div class="d-flex flex-column align-items-center">
          <div class="d-flex align-items-center">
            <h3>
              {{ formatMoneyShekels(baseValueAfterSliderChange - baseMarkValue) }}
            </h3>
            <template v-if="sliderValue !== 0">
              <FullArrowUpIcon v-if="baseValueAfterSliderChange > baseMarkValue" class="text-danger" />
              <FullArrowDownIcon v-else class="text-success" />
            </template>
          </div>
          <small class="text-muted">
            {{
              sliderValue > 0
                ? $t('terms.supplierTerms.purchaseHistoryTop20.annualImpactSlider.estimatedAnnualAdditionalExpense')
                : $t('terms.supplierTerms.purchaseHistoryTop20.annualImpactSlider.estimatedAnnualSaving')
            }}
          </small>
        </div>
      </div>
    </div>
  </DashboardCard>
</template>

<script>
import { computed } from 'vue';
import { useCurrency } from '@/locale/useCurrency';
import { FullArrowUpIcon, FullArrowDownIcon } from '@/assets/icons';
import { DashboardCard } from '@/modules/core';

import {
  formatCurrencyOrDash,
  formatPercentWith2Fractions,
} from '@/modules/purchase-management/purchaseManagementFormatters';

export default {
  components: { FullArrowUpIcon, FullArrowDownIcon, DashboardCard },
  props: {
    initialValue: { type: Number, default: -10 },
    minimum: { type: Number, default: -100 },
    maximum: { type: Number, default: 100 },
    showMarks: { type: Boolean, default: false },
    baseMarkValue: { type: Number, default: 0 },
  },
  setup() {
    const { currencyFormat: baseCurrencyFormat } = useCurrency();
    const currencyFormat = computed(() => ({ ...baseCurrencyFormat.value, maximumFractionDigits: 0 }));
    return {
      currencyFormat,
    };
  },
  data() {
    return {
      sliderValue: this.initialValue,
    };
  },
  computed: {
    marks() {
      if (!this.showMarks) return {};
      return {
        [this.minimum]: this.buildMark(this.minimum),
        [this.minimum / 2]: this.buildMark(this.minimum / 2),
        0: this.buildMark(0),
        [this.maximum / 2]: this.buildMark(this.maximum / 2),
        [this.maximum]: this.buildMark(this.maximum),
      };
    },
    baseValueAfterSliderChange() {
      return this.baseMarkValue * ((100 + this.sliderValue) / 100);
    },
  },
  methods: {
    formatPercent(value) {
      return formatPercentWith2Fractions(value);
    },
    formatMoneyShekels(value) {
      return formatCurrencyOrDash(value, this.currencyFormat);
    },
    buildMark(percent) {
      return {
        label: this.$createElement('div', { class: 'd-flex align-items-center flex-column' }, [
          this.$createElement('small', { class: 'text-typography-primary' }, this.formatPercent(percent)),
          this.$createElement('small', { class: 'text-muted' }, [
            this.formatMoneyShekels(this.baseMarkValue * ((100 + percent) / 100) - this.baseMarkValue),
          ]),
        ]),
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .el-slider__bar {
  background-color: transparent;
}

::v-deep .el-slider__button {
  border: 3px solid $white;
  background-color: $primary;
}

.cards-body {
  flex: 1 1 auto;
  padding: 0;
  margin: 0 0.5rem;
  .calcuated-price-summary {
    margin-top: 3rem;
  }
}
</style>
