import { SUPPLIERS_TABLE_HEADERS } from './suppliersTableConstants';
import i18n from '@/imports/startup/client/i18n';
import { EXCEL_TYPES } from '../../excel';
import { COLUMN_CATEGORIES } from '../supplierProductsPurchaseManagement/supplierProductsTableConstants';

export const getDefaultPreset = (columns) => {
  const columnsToShowFromDefaultCategory = [
    SUPPLIERS_TABLE_HEADERS.SUPPLIER,
    SUPPLIERS_TABLE_HEADERS.PRODUCT_COUNT,
    SUPPLIERS_TABLE_HEADERS.TOTAL_PURCHASES,
  ];

  return columns.map((column) => {
    if (column.category === COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED) {
      return {
        ...column,
        hidden: false,
        checked: true,
      };
    }

    if (columnsToShowFromDefaultCategory.includes(column.key)) {
      return {
        ...column,
        hidden: false,
        checked: true,
      };
    } else {
      return {
        ...column,
        hidden: true,
        checked: false,
      };
    }
  });
};

export const getComparisonPreset = (columns) => {
  const columnsToShowFromDefaultCategory = [
    SUPPLIERS_TABLE_HEADERS.SUPPLIER,
    SUPPLIERS_TABLE_HEADERS.PRODUCT_COUNT,
    SUPPLIERS_TABLE_HEADERS.TOTAL_PURCHASES,
  ];

  return columns.map((column) => {
    if (columnsToShowFromDefaultCategory.includes(column.key)) {
      return {
        ...column,
        hidden: false,
        checked: true,
      };
    }

    return {
      ...column,
      hidden: true,
      checked: false,
    };
  });
};

export const getSuppliersTableColumns = (activeSort, supplierSearchFilter) => {
  return [
    {
      header: i18n.t('terms.termsTable.header.supplier'),
      key: SUPPLIERS_TABLE_HEADERS.SUPPLIER,
      width: '205px',
      hidden: false,
      editable: false,
      sortCallback: (direction) => (activeSort.value = { direction, columnKey: SUPPLIERS_TABLE_HEADERS.SUPPLIER }),
      filterActive: !!supplierSearchFilter,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
    },
    {
      header: i18n.t('terms.termsTable.header.orderCount'),
      key: SUPPLIERS_TABLE_HEADERS.ORDER_COUNT,
      width: '135px',
      hidden: true,
      editable: true,
      isComparisonColumn: true,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
    },
    {
      header: i18n.t('terms.termsTable.header.productCount'),
      key: SUPPLIERS_TABLE_HEADERS.PRODUCT_COUNT,
      width: '125px',
      hidden: false,
      editable: true,
      isComparisonColumn: true,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
    },
    {
      header: i18n.t('terms.termsTable.header.totalPurchases'),
      key: SUPPLIERS_TABLE_HEADERS.TOTAL_PURCHASES,
      width: '182px',
      hidden: false,
      editable: false,
      isComparisonColumn: true,
      type: EXCEL_TYPES.CURRENCY,
      sortCallback: (direction) =>
        (activeSort.value = { direction, columnKey: SUPPLIERS_TABLE_HEADERS.TOTAL_PURCHASES }),
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
    },
    {
      header: i18n.t('terms.termsTable.header.productCountWithDifferentPrices'),
      key: SUPPLIERS_TABLE_HEADERS.PRODUCT_COUNT_WITH_DIFFERENT_PRICES,
      width: '182px',
      hidden: false,
      editable: true,
      customClass: 'border-right',
      category: COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED,
    },
    {
      header: i18n.t('terms.termsTable.header.impactOnPeriod'),
      key: SUPPLIERS_TABLE_HEADERS.DIFFERENCE,
      width: '182px',
      hidden: false,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED,
    },
    {
      header: i18n.t('terms.termsTable.header.annualImpact'),
      key: SUPPLIERS_TABLE_HEADERS.ANNUAL_IMPACT,
      width: '182px',
      hidden: false,
      editable: true,
      sortCallback: (direction) => (activeSort.value = { direction, columnKey: SUPPLIERS_TABLE_HEADERS.ANNUAL_IMPACT }),
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED,
    },
  ];
};
