import { isNil } from 'ramda';
import i18n from '@/imports/startup/client/i18n';
import { exportToExcel } from '@/imports/ui/file_exports';
import { SUPPLIERS_TABLE_HEADERS } from './suppliersTableConstants';
import { buildColumnsByTableViewForExcel } from '../../excel';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

export const exportSuppliers = ({ suppliersItems, tableColumns, dateRanges, currencyFormat }) => {
  const amountOfComparison = dateRanges.length;
  if (amountOfComparison === 1) {
    exportSuppliersWithoutComparison({
      suppliersItems: suppliersItems.map((comparisonItem) => comparisonItem['comparison-0']),
      tableColumns,
      dateRange: dateRanges[0],
      currencyFormat,
    });
    return;
  }

  const columns = buildColumnsByTableViewForExcel({
    tableColumns,
    amountOfComparison,
    dateRanges,
    currencyFormat,
    columnsNotInComparison: [
      SUPPLIERS_TABLE_HEADERS.SUPPLIER,
      SUPPLIERS_TABLE_HEADERS.PRODUCT_COUNT_WITH_DIFFERENT_PRICES,
      SUPPLIERS_TABLE_HEADERS.ANNUAL_IMPACT,
      SUPPLIERS_TABLE_HEADERS.DIFFERENCE,
    ],
  });

  const isThreePeriods = dateRanges.length === 3;
  const translationKey = isThreePeriods ? 'suppliersComparisonThreePeriod' : 'suppliersComparisonTwoPeriod';
  const translationDateRanges = {
    firstPeriod: dateRanges[0],
    secondPeriod: dateRanges[1],
    thirdPeriod: dateRanges[2],
  };

  const metadata = {
    filename: i18n.t(`terms.supplierTerms.supplierTermTable.excel.fileNames.${translationKey}`, {
      ...translationDateRanges,
    }),
    sheetname: i18n.t('terms.supplierTerms.supplierTermTable.excel.suppliersSheetname'),
    direction: i18n.t('direction'),
    columns,
  };

  const data = suppliersItems.map((comparisonItem) => {
    const comparisonItemValues = {};

    for (let i = 0; i < amountOfComparison; i++) {
      const { productCount, orderCount, price } = comparisonItem[`comparison-${i}`];

      comparisonItemValues[`productCount-${i}`] = productCount ?? MISSING_DATA_TEXT;
      comparisonItemValues[`orderCount-${i}`] = orderCount ?? MISSING_DATA_TEXT;
      comparisonItemValues[`totalPurchases-${i}`] = price ?? MISSING_DATA_TEXT;
    }

    const currentComparisonItem = comparisonItem['comparison-0'];
    const productCountWithDifferentPrices = isNil(currentComparisonItem.productCountWithDifferentPrices)
      ? MISSING_DATA_TEXT
      : currentComparisonItem.productCountWithDifferentPrices;
    const annualImpact =
      productCountWithDifferentPrices === 0 || productCountWithDifferentPrices === MISSING_DATA_TEXT
        ? MISSING_DATA_TEXT
        : currentComparisonItem.annualImpact;
    const difference =
      productCountWithDifferentPrices === 0 || productCountWithDifferentPrices === MISSING_DATA_TEXT
        ? MISSING_DATA_TEXT
        : currentComparisonItem.difference;

    return {
      supplier:
        comparisonItem['comparison-0'].supplier.name ??
        comparisonItem['comparison-1'].supplier.name ??
        comparisonItem['comparison-2'].supplier.name ??
        MISSING_DATA_TEXT,
      annualImpact,
      difference,
      productCountWithDifferentPrices,
      ...comparisonItemValues,
    };
  });

  exportToExcel(metadata, data);
};

const exportSuppliersWithoutComparison = ({ suppliersItems, tableColumns, dateRange, currencyFormat }) => {
  const columns = buildColumnsByTableViewForExcel({ tableColumns, currencyFormat });
  const metadata = {
    filename: i18n.t('terms.supplierTerms.supplierTermTable.excel.fileNames.suppliersSummary', { period: dateRange }),
    sheetname: i18n.t('terms.supplierTerms.supplierTermTable.excel.suppliersSheetname'),
    direction: i18n.t('direction'),
    columns,
  };

  const data = suppliersItems.map(({ supplier, price, ...rest }) => {
    const productCountWithDifferentPrices = isNil(rest.productCountWithDifferentPrices)
      ? MISSING_DATA_TEXT
      : rest.productCountWithDifferentPrices;
    const annualImpact =
      productCountWithDifferentPrices === 0 || productCountWithDifferentPrices === MISSING_DATA_TEXT
        ? MISSING_DATA_TEXT
        : rest.annualImpact;
    const difference =
      productCountWithDifferentPrices === 0 || productCountWithDifferentPrices === MISSING_DATA_TEXT
        ? MISSING_DATA_TEXT
        : rest.difference;
    return {
      supplier: supplier.name,
      totalPurchases: price,
      ...rest,
      productCountWithDifferentPrices,
      annualImpact,
      difference,
    };
  });

  exportToExcel(metadata, data);
};
