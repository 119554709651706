export const SUPPLIERS_TABLE_HEADERS = {
  SUPPLIER: 'supplier',
  PRODUCT_COUNT: 'productCount',
  TOTAL_PURCHASES: 'totalPurchases',
  ORDER_COUNT: 'orderCount',
  PRODUCT_COUNT_WITH_DIFFERENT_PRICES: 'productCountWithDifferentPrices',
  DIFFERENCE: 'difference',
  ANNUAL_IMPACT: 'annualImpact',
};

export const SUPPLIER_WITH_NO_DATA = {
  supplier: {
    id: null,
    name: null,
  },
  amount: null,
  productCount: null,
  orderCount: null,
  firstOrder: {
    date: null,
  },
  lastOrder: {
    date: null,
  },
};
