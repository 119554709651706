import { gql } from '@apollo/client/core';

export const TOP_PURCHASED_PRODUCTS_QUERY = gql`
  query topPurchasedProducts($tenantId: ID!, $limit: Int!, $fromDate: ISODate, $toDate: ISODate) {
    orderItemAggregationNew(
      tenantId: $tenantId
      fromDate: $fromDate
      toDate: $toDate
      limit: $limit
      sortBy: "sumTotalNetPricePerUnit"
      aggregateBy: "product"
      monthlyAverage: true
      withAssociated: false
    ) {
      nodes {
        product {
          id
          name
          sku
        }
        supplier {
          name
        }
        pricedOrderItemAverage
        monthlyAverageTotalQuantity
        monthlyAverageSumTotalNetPricePerUnit
      }
    }
  }
`;
